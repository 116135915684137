





































import { Component, Vue, Prop } from 'nuxt-property-decorator';
import MailchimpSubscribe from 'vue-mailchimp-subscribe';

@Component({
  components: {
    MailchimpSubscribe,
  },
})
export default class BlockNewsletter extends Vue {
  @Prop(String)
  backgroundColor!: string

  @Prop(String)
  title!: string

  @Prop(String)
  subtitle!: string

  @Prop({ type: String, required: true })
  mailchimpUrl!: string

  @Prop({ type: String, required: true })
  mailchimpUserId!: string

  @Prop({ type: String, required: true })
  mailchimpListId!: string

  @Prop({ type: String, required: true })
  buttonText!: string

  successText = 'Klart!';
  loadingText = 'Skickar...';
}
